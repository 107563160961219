import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updatePassword } from "../../actions/auth";
import { setAlert } from "../../actions/alert";
import { setLoader } from "../../actions/loader";
import PropTypes from "prop-types";

function ResetPassword({
  updatePassword,
  user,
  loading,
  setAlert,
  loader,
  setLoader,
}) {
  const [resPassword, setResPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [id, setId] = useState("");
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  const changePassword = (e) => {
    setResPassword({ ...resPassword, [e.target.name]: e.target.value });
  };

  const onSubmitUpdatePassword = async (e) => {
    e.preventDefault();
    if (resPassword.new_password !== resPassword.confirm_password) {
      setAlert("Password did not match", "danger");
    } else {
      setCheck(true);
      setLoader();
      const userData = {
        ...resPassword,
        id
         
      };
      await updatePassword(userData);
      setCheck(false);
      setResPassword({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
    }
  };
  return (
    <div className="container px-4 mb-5">
      <h4 className="text-white personal-info mb-3 text-left">
        Change Password
      </h4>
      <div className="profile-info-box bg-dark">
        <form onSubmit={(e) => onSubmitUpdatePassword(e)}>
          <div className="row d-flex justify-content-center ">
            <div className="col col-lg-6 col-12 px-2">
              <div className="form-group form-group-mr-profile">
                <label className="input-label">Current Password </label>
                <input
                  className="form-control input-dark password-input"
                  type="password"
                  name="old_password"
                  placeholder=".........."
                  value={resPassword.old_password}
                  onChange={(e) => changePassword(e)}
                  required
                />
                <span
                  className="input-show"
                  onClick={(e) =>
                    e.target.parentElement.previousElementSibling.type ===
                    "password"
                      ? (e.target.parentElement.previousElementSibling.type =
                          "text")
                      : (e.target.parentElement.previousElementSibling.type =
                          "password")
                  }
                >
                  {" "}
                  <img
                    src="./assets/images/show.svg"
                    alt=""
                    id="#password"
                    className="show-svg"
                  />{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center ">
            <div className="col col-lg-6 col-12 pr-xl-1">
              <div className="form-group form-group-mr-profile">
                <label className="input-label">New Password </label>
                <input
                  className="form-control input-dark password-input"
                  type="password"
                  name="new_password"
                  placeholder="............"
                  value={resPassword.new_password}
                  onChange={(e) => changePassword(e)}
                  required
                />
                <span
                  className="input-show"
                  onClick={(e) =>
                    e.target.parentElement.previousElementSibling.type ===
                    "password"
                      ? (e.target.parentElement.previousElementSibling.type =
                          "text")
                      : (e.target.parentElement.previousElementSibling.type =
                          "password")
                  }
                >
                  {" "}
                  <img
                    src="./assets/images/show.svg"
                    alt=""
                    className="show-svg"
                  />{" "}
                </span>
              </div>
            </div>
            <div className="col col-lg-6 col-12 px-2">
              <div className="form-group form-group-mr-profile">
                <label className="input-label">Confirm New Password </label>
                <input
                  className="form-control input-dark password-input"
                  type="password"
                  name="confirm_password"
                  placeholder="............"
                  value={resPassword.confirm_password}
                  onChange={(e) => changePassword(e)}
                  required
                />
                <span
                  className="input-show"
                  onClick={(e) =>
                    e.target.parentElement.previousElementSibling.type ===
                    "password"
                      ? (e.target.parentElement.previousElementSibling.type =
                          "text")
                      : (e.target.parentElement.previousElementSibling.type =
                          "password")
                  }
                >
                  <img
                    src="./assets/images/show.svg"
                    className="show-svg"
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="col col-md-12 text-center mt-5">
            {loader && check ? (
              <button className="btn btn-3 update-pass" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm text-light mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                <strong>Loading...</strong>
              </button>
            ) : (
              <button className="btn btn-3 update-pass">Update</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

ResetPassword.propTypes = {
  updatePassword: PropTypes.func.isRequired,
  user: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  setAlert: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  updatePassword,
  setAlert,
  setLoader,
})(ResetPassword);

import React, {useEffect} from "react";
import IntegrationHead from "./IntegrationHead";
import ConnectYoutube from "./ConnectYoutube";
import {fetchSocialAccounts} from "../../actions/createPodreel";
import {useDispatch, useSelector} from "react-redux"

const Integration = () => {
const dispatch = useDispatch();
const auth = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(fetchSocialAccounts(auth.user.user_id))
  },[]);


  return (
    <div
      className="main-wrapper"
      style={{ height: "92vh", paddingTop: "5rem" }}
    >
      <IntegrationHead />
      <section className="my-connection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="text-white">My Connection</h4>
                            </div>
                        </div>

                        {/* data={socialData.youtube}  */}
                        <ConnectYoutube key="2" />
                       

                    </div>
                </section>
    </div>
  );
};

export default Integration;

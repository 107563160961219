import React from "react";

const SupportFooter = () => {
  return (
    <section id="cs-cta">
      <div className="container">
        <div className="row">
          <div className="col colmn-1 col-12">
            <div className="title-area text-center mb-4">
              <h6
                className="sub-heading text-white mb-1"
                style={{ opacity: "0.7" }}
              >
                CUSTOMER SUPPORT
              </h6>
              <h3 className="text-white font-weight-normal">
                Can’t find your answer?
              </h3>
            </div>
            <div
              className="cont-txt text-center w-100 mx-auto mb-4"
              style={{ maxWidth: "610px" }}
            >
              <p
                className="text-white md-txt"
                style={{ fontWeight: 500, lineHeight: "1.78" }}
              >
                We’re here to help! From general communication to specific
                Podreel platform questions, please reach out so we can assist.
              </p>
            </div>
            <div className="btn-wrapper text-center">
              <a
                href="https://support.vineasx.com"
                target="blank"
                className="cs-btn btn btn-2"
              >
                Contact Podreel
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportFooter;

import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer id="app-footer">
          <div className="container-fluid px-4">
            <div className="row align-items-center">
              <div className="col colmn-1 col-lg-3 col-12 mb-lg-0 mb-2">
                <ul id="footer-nav" className="">
                  <li className="nav-item">
                    <a
                      href="https://reelapps.io/terms.html"
                      target="blank"
                      className="text-link pr-0"
                    >
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://reelapps.io/privacy.html"
                      target="blank"
                      className="text-link pr-0"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://support.vineasx.com/"
                      target="blank"
                      className="text-link pr-0"
                    >
                      Support
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col colmn-2 col-lg-7 col-12">
                <div className="row inner-row align-items-center justify-content-lg-center mx-0">
                  <div className="copyright pr-lg-4 order-lg-1 order-2 text-center">
                    <p className="text-link">
                      © {new Date().getFullYear()} All Rights Reserved to
                      <a className="text-link"
                      target="blank"
                      href="https://vineasx.com/"
                      >VineaSX Solutions LLC.</a>
                       Developed and Maintained by{" "}
                      <a
                        className="text-link"
                        target="blank"
                        href="https://www.vega6.com/"
                      >
                        vega6
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col colmn-2 col-lg-2 col-12">
                <div className="row inner-row align-items-center justify-content-lg-end mx-0">
                  <div className="copyright pr-4 order-lg-1 order-2">
                    <a
                      className="text-white"
                      target="blank"
                      style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                      href="https://reelapps.io/"
                    >
                      <img src="./assets/images/reelapps.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;

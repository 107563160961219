import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resetClientPassword,
  resetTeamPassword,
} from "../../actions/accountManagement";
import { setAlert } from "../../actions/alert";
import Alert from "../alert/Alert";
import { removeAlert } from "../../actions/alert";

const ResetPassword = ({
  close,
  appType,
  user_id,
  resetClientPassword,
  resetTeamPassword,
  id,
  setAlert,
  removeAlert,
  showAlert,
}) => {
  const [details, setDetails] = useState({
    password: false,
    confirm_password: false,
    buttonText: "Reset Password",
  });

  useEffect(() => {
    showAlert(false);
    removeAlert();

    return () => {
      showAlert(true);
    }
  }, [removeAlert, showAlert]);

  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (details.password !== details.confirm_password) {
      setAlert("Your password is not matched", "danger");
      return;
    }

    setDetails({
      ...details,
      buttonText: "Password Updating...",
    });

    const data = {
      password: details.password,
      confirm_password: details.confirm_password, 
      id: id
    };

    if (appType === "client") {
      await resetClientPassword(data, user_id, close);
    } else if (appType === "team") {
      await resetTeamPassword(data, user_id, close);
    }

    setDetails({
      ...details,
      buttonText: "Reset Password",
    });
  };
  return (
    <div>
      <div
        id="reset-password"
        className="modal fade d-block show"
        style={{ background: "rgba(0,0,0,0.9)" }}
        tabIndex={-1}
        role="dialog"
        data-backdrop="static"
        aria-labelledby="delete-warn"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mx-auto"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close mb-3 text-right"
              data-dismiss="modal"
              aria-label="Close"
              style={{ opacity: 1 }}
              onClick={close}
            >
              <img
                className="img-fluid"
                src="./assets/images/close.svg"
                alt="Close"
              />
            </button>
            <div
              className="modal-body bg-white text-center"
              style={{ maxWidth: "644px", minHeight: "364px" }}
            >
              <div className="img-wrapper mt-5 mb-3 pb-1">
                <img
                  className="img-fluid"
                  src="./assets/images/warning.svg"
                  alt="Warning"
                />
              </div>
              <h4
                className="modal-title mb-2"
                id="delete-warn"
                style={{ color: "#000000" }}
              >
                Reset Password
              </h4>

              <Alert authStyle={"authStyle"} />
              <div className="col-10 mb-4 mx-auto">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="New Password"
                      autoComplete="off"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="confirm_password"
                      className="form-control"
                      placeholder="Confirm Password"
                      autoComplete="off"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    id="confirm"
                    type="submit"
                    className="btn btn-3 text-white mb-2"
                  >
                    {details.buttonText}
                  </button>
                </form>
              </div>

              {/* <div className="modal-footer border-top-0 p-0 justify-content-center mb-5">
               
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  close: PropTypes.func.isRequired,
  appType: PropTypes.string.isRequired,
  user_id: PropTypes.string.isRequired,
  resetClientPassword: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  setAlert: PropTypes.func.isRequired,
  resetTeamPassword: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};

export default connect(null, {
  resetClientPassword,
  setAlert,
  resetTeamPassword,
  removeAlert,
})(ResetPassword);

import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { forgot_password } from "../../actions/auth";
import { removeAlert } from "../../actions/alert";
import { setLoader } from "../../actions/loader";
import PropTypes from "prop-types";
import Alert from "../alert/Alert";

function Forgot({
  forgot_password,
  isAuthenticated,
  removeAlert,
  setLoader,
  loader,
}) {
  const [email, setEmail] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    setLoader();
    forgot_password(email);
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="login-page">
      <section id="login-content">
        <div className="container-fluid">
          <div className="row mx-sm-auto align-items-center">
            <div className="col colmn-1 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pr-lg-5">
              <div className="content-wrapper text-center">
                <div className="img-wrapper mb-5">
                  <img
                    className="img-fluid"
                    src="./assets/images/PodReel_logo_v2_040121.png"
                    alt="Logo"
                    width="450px"
                    height="auto"
                  />
                </div>
                <h4 className="text-white font-weight-normal">
                Turn any audio or podcast episode into gorgeous video for your social media within minutes.
                </h4>
              </div>
            </div>
            <div
              className="col colmn-2 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pl-lg-5"
              style={{ minHeight: "31rem" }}
            >
              <form id="login-form" onSubmit={(e) => sendEmail(e)}>
                <div className="form-title text-center">
                  <h2 className="text-white">Enter your Email address</h2>
                  <div
                    style={{ height: "7rem", width: "100%", marginTop: "10px" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Alert authStyle={"authStyle"} />
                  </div>
                </div>
                <div className="input-group mb-4">
                  <input
                    id="email"
                    className="form-control"
                    type="email"
                    name="email"
                    autoComplete="off"
                    required=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="pass" className="text-white">
                    Email Address
                  </label>
                  <span className="underline"> </span>
                </div>
                <div className="btn-wrapper text-center mb-4">
                  {loader ? (
                    <button
                      className="btn btn-1 text-white text-uppercase"
                      type="button"
                      style={{
                        padding: "15px",
                        fontWeight: "700",
                        maxHeight: "50px",
                      }}
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm text-light mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <strong>Loading...</strong>
                    </button>
                  ) : (
                    <button
                      className="btn btn-1 text-white text-uppercase"
                      type="submit"
                    >
                      Submit
                    </button>
                  )}
                </div>
                <div className="form-footer text-center">
                  <Link to="/">
                    <p className="text-white sm-txt">Have Login ?</p>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Forgot.propTypes = {
  forgot_password: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  removeAlert: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  forgot_password,
  removeAlert,
  setLoader,
})(Forgot);

import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { connect, useSelector } from "react-redux";
import { saveGeneralData } from "../../../../actions/createPodreel";

const General = (props) => {
  const general = useSelector((state) => state.createPodreel.customize.general);
  const [showColorP, setShowColorP] = useState(false);
  const [backColor, setBackColor] = useState("#f56262");
  const [property, setProperty] = useState({
    dimension: "16:9",
    width: 480,
    height: 270,
    backgroundColor: backColor,
  });

  useEffect(() => {
    setProperty({
      ...property,
      dimension: props.customize.general.dimension,
      width: props.customize.general.width,
      height: props.customize.general.height,
      backgroundColor: props.customize.general.backgroundColor,
    });
  }, [props.customize]);

  const showColorPicker = () => {
    setShowColorP(!showColorP);
  };
  const handleChangeComplete = (color) => {
    setBackColor(color.hex);
    const propertyLocal = {
      dimension: props.customize.general.dimension,
      width: props.customize.general.width,
      height: props.customize.general.height,
      backgroundColor: color.hex,
    };
    props.saveGeneralData(propertyLocal);
  };
  const handleClose = () => {
    setShowColorP(false);
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
    left: "-140px",
    top: "30px",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const handleRatioChange = (event) => {
    const ratio = event.target.value;
    const propertyLocal = {
      dimension: ratio,
      width: 0,
      height: 0,
      backgroundColor: props.customize.general.backgroundColor,
    };
    switch (ratio) {
      //   case "4:3":
      //     propertyLocal.width = 480;
      //     propertyLocal.height = 360;
      //     break;
      //   case "3:4":
      //     propertyLocal.width = 270;
      //     propertyLocal.height = 360;
      //     break;
      case "1:1":
        propertyLocal.width = 360;
        propertyLocal.height = 360;
        break;
      case "16:9":
        propertyLocal.width = 480;
        propertyLocal.height = 270;
        break;
      case "9:16":
        propertyLocal.width = 270;
        propertyLocal.height = 480;
        break;
      default:
        propertyLocal.width = 480;
        propertyLocal.height = 270;
        break;
    }
    setProperty(propertyLocal);
    props.saveGeneralData(propertyLocal);
  };
  return (
    <div
      className="tab-pane fade"
      id="v-pills-home"
      role="tabpanel"
      aria-labelledby="v-pills-home-tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="general-content">
            <div className="row">
              <div className="col-md-12">
                <div className="dimension">
                  <label>Choose an Aspect Ratio:</label>
                  <select
                    onChange={handleRatioChange}
                    style={{ float: "right" }}
                  >
                    {/* <option selected={general.dimension === "4:3"} value="4:3" >4:3</option>
                                        <option selected={general.dimension === "3:4"} value="3:4" >3:4</option> */}

                    <option
                      selected={general.dimension === "16:9"}
                      value="16:9"
                    >
                      16:9
                    </option>
                    <option selected={general.dimension === "1:1"} value="1:1">
                      1:1
                    </option>
                    <option
                      selected={general.dimension === "9:16"}
                      value="9:16"
                    >
                      9:16
                    </option>
                  </select>
                </div>
                <div
                  className="colorpickers d-flex align-items-center mt-4 justify-content-between"
                  style={{ position: "sticky" }}
                >
                  <div className="background">
                    <h6>Select a Background Color</h6>
                  </div>
                  <div className="colorpicke">
                    <div id="cp-component" className="input-group">
                      <button
                        type="button"
                        style={{
                          width: "70px",
                          height: "22px",
                          backgroundColor:
                            general.backgroundColor === "#ffffff"
                              ? backColor
                              : general.backgroundColor,
                          marginLeft: "10px",
                          border: "none",
                          float: "right",
                          position: "relative",
                        }}
                        onClick={showColorPicker}
                      >
                        {" "}
                      </button>

                      {showColorP ? (
                        <div style={popover}>
                          <div style={cover} onClick={handleClose} />
                          <SketchPicker
                            color={
                              general.backgroundColor === "#ffffff"
                                ? backColor
                                : general.backgroundColor
                            }
                            onChange={handleChangeComplete}
                          />
                        </div>
                      ) : null}

                      <span className="input-group-addon">
                        <i
                          style={{ width: 50, height: 38, marginTop: "-1px" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveGeneralData: (data) => dispatch(saveGeneralData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(General);

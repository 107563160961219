import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';


function Myvideohead(props) {

  const totalVideo = useSelector(state => state.video.allProject);
  const auth = useSelector(state => state.auth);

  return (
    <>
      <section className="myvideo-head-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pl-0 d-flex align-items-center">
              <div className="myvidoe-prodcast-head">
                <ul className="list-inline d-flex  m-0">
                  <li>My Podcast</li>
                  <li>{totalVideo.length} Video</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <div className="create-head-input d-flex justify-content-end align-items-center ">
                <div className="input-group w-50">
                  <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"

                    onChange={event => { props.search(event.target.value) }}

                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2"><i className="fas fa-search" /></span>
                  </div>
                </div>
                {auth.user.role !== "3" ? <>
                  <div className="createvideo-btn">
                    <Link to="/create-podcast">Create Podcast</Link>
                  </div>
                </> : ''
                }
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Myvideohead

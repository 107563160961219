import React, { useState, useEffect, useRef } from "react";
import Helmet from "react-helmet";
import { baseURL } from "../../global/global";
import { Link, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { trimmedAudioData } from "../../actions/createPodreel";
import CreateHead from "./CreateHead";
import WaveSurfer from "wavesurfer.js";
import {
  saveAudioCaption,
  saveTransAudioCaption,
  duration,
} from "../../actions/createPodreel";
import { useSelector, useDispatch, connect } from "react-redux";
import RegionsPlugin from "wavesurfer.js/src/plugin/regions";

const ProfileSection = ({ auth: { user } }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);
  const [lang1, setLang1] = useState([]);
  const [lang, setLang] = useState([]);
  const [captionIsEnabled, setCaptionIsEnabled] = useState(true);
  const [captionIsEnabled1, setCaptionIsEnabled1] = useState(true);
  const [c, setC] = useState(false);
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState("0");
  const [endTime, setEndTime] = useState("15");
  const [addCaption, setAddCaption] = useState("");
  const [addCaptionCode, setCaptionCode] = useState("");
  const [addCaptionLang, setCaptionLang] = useState("");
  const [loader, setLoader] = useState(false);
  const [hours, setHours] = useState("0");
  const [mins, setMins] = useState("0");
  const [sec, setSec] = useState("0");

  const [startTrimHour, setStartTrimHour] = useState("0");
  const [startTrimMin, setStartTrimMin] = useState("0");
  const [startTrimSec, setStartTrimSec] = useState("0");

  const [endTrimHour, setEndTrimHour] = useState("0");
  const [endTrimMin, setEndTrimMin] = useState("0");
  const [endTrimSec, setEndTrimSec] = useState("0");

  const [totalTrimDurationS, setTotalTrimDurationS] = useState("0");
  const [totalTrimDurationM, setTotalTrimDurationM] = useState("0");
  const [totalTrimDurationH, setTotalTrimDurationH] = useState("0");

  const audioUrl = useSelector(
    (state) => state.createPodreel.audio_file_data.url
  );
  const strtTime = useSelector(
    (state) => state.createPodreel.duration.startTime
  );
  const edTime = useSelector((state) => state.createPodreel.duration.endTime);
  const campaign_id = useSelector(
    (state) => state.createPodreel.audio_file_data.campaingCode
  );
  const checkBox = useSelector(
    (state) => state.createPodreel.audio_data.audioAddCaption
  );
  const selectedLang = useSelector(
    (state) => state.createPodreel.audio_data.audioAddCaption
  );

  //Edited Data
  const time = useSelector((state) => state.createPodreel.duration);
  let checked =
    checkBox === null
      ? false
      : checkBox.add_caption !== ""
      ? checkBox.add_caption
      : false; //attribute

  // useEffect(() => {
  //   if(location.pathname === "/ProfileSection" && audioUrl === ""){
  //     history.push("/dashboard");
  //   }
  // },[audioUrl])

  useEffect(() => {
    setC(checked);
    if (checked === true) {
      setCaptionIsEnabled(false);
      setAddCaption("yes");
    } else if (checked === false) {
      setCaptionIsEnabled(true);
      setAddCaption("no");
    } else {
      setCaptionIsEnabled(false);
      setAddCaption("yes");
    }
  }, [checked]);

  useEffect(() => {
    const url = audioUrl;

    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      responsive: "true",
      height: 45,
      progressColor: "#f56262",
      cursorWidth: 3,
      plugins: [
        RegionsPlugin.create({
          regionsMinLength: 2,
          regions: [
            {
              start: time.startTime === "" ? startTime : time.startTime,
              end: time.endTime === "" ? endTime : time.endTime,
              loop: false,
              drag: true,
              resize: true,
              color: "rgba(48, 107, 177, 0.7)",
            },
          ],
        }),
      ],
      dragSelection: "true",
    });

    wavesurfer.current.on("ready", function () {
      setStartTrimSec(time.startTime % 60);
      setStartTrimMin(Math.floor(time.startTime / 60));
      setStartTrimHour(Math.floor(time.startTime / 3600));

      let durationOnLoad = wavesurfer.current.getDuration();
      setLoader(false);
      setReady(true);

      let secOnLoad = Math.floor(wavesurfer.current.getDuration() % 60);
      let minsOnLoad = Math.floor(wavesurfer.current.getDuration() / 60);
      let hoursOnLoad = Math.floor(wavesurfer.current.getDuration() / 3600);

      setSec(secOnLoad);
      setMins(minsOnLoad);
      setHours(hoursOnLoad);

      setTotalTrimDurationS(secOnLoad);
      setTotalTrimDurationM(minsOnLoad);
      setTotalTrimDurationH(hoursOnLoad);

      const data = {
        startT: time.startTime === "" ? startTime : time.startTime,
        endT: time.endTime === "" ? endTime : time.endTime,
        totalD:
          time.totalDuration === "" ? endTime - startTime : time.totalDuration,
      };
      dispatch(duration(data));
      setEndTrimSec(time.endTime === "" ? secOnLoad : time.endTime % 60);
      setEndTrimMin(
        time.endTime === "" ? minsOnLoad : Math.floor(time.endTime / 60)
      );
      setEndTrimHour(
        time.endTime === "" ? hoursOnLoad : Math.floor(time.endTime / 3600)
      );
    });

    if (url !== "") {
      wavesurfer.current.load(url, setLoader(true));
    }

    wavesurfer.current.on("finish", function () {
      setPlaying(false);
    });

    wavesurfer.current.on("region-updated", function (region) {
      setStartTime(Math.floor(region.start));
      setEndTime(Math.floor(region.end));

      let startTimingS = Math.floor(region.start % 60);
      let startTimingM = Math.floor(region.start / 60);
      let startTimingH = Math.floor(region.start / 3600);

      let endTimingS = Math.floor(region.end % 60);
      let endTimingM = Math.floor(region.end / 60);
      let endTimingH = Math.floor(region.end / 3600);

      setStartTrimSec(startTimingS);
      setStartTrimMin(startTimingM);
      setStartTrimHour(startTimingH);

      setEndTrimSec(endTimingS);
      setEndTrimMin(endTimingM);
      setEndTrimHour(endTimingH);

      // setTotalTrimDurationS(Math.floor(region.end%60) - Math.floor(region.start%60));
      setTotalTrimDurationS(Math.abs(endTimingS - startTimingS));
      
      setTotalTrimDurationM(
        Math.floor(region.end / 60) - Math.floor(region.start / 60)
      );
      
      // setTotalTrimDurationH(endTrimHour - startTrimHour);
      setTotalTrimDurationH(
        Math.floor(region.end / 3600) - Math.floor(region.start / 3600)
      );
      // console.log(Math.floor(region.start), Math.floor(region.end))
      const data = {
        startT: Math.floor(region.start),
        endT: Math.floor(region.end),
        totalD: Math.floor(region.end) - Math.floor(region.start),
      };

      dispatch(duration(data));
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [audioUrl]);

  useEffect(() => {
    axios.get("https://app.podreel.io/api/transcribe-languages").then((res) => {
      if (res.data.status === true) {
        setLang1(res.data.data.languages);
      }
    });
  }, []);

  useEffect(() => {
    axios.get("https://app.podreel.io/api/translate-languages").then((res) => {
      if (res.data.status === true) {
        setLang(res.data.data.tranlatelanguages);
      }
    });
  }, []);

  const play = () => {
    setPlaying(!playing);
    wavesurfer.current.playPause();

    wavesurfer.current.on("audioprocess", function () {
      let currentSec = wavesurfer.current.getCurrentTime() % 60;
      let currentMin = wavesurfer.current.getCurrentTime() / 60;
      let currentHours = wavesurfer.current.getCurrentTime() / 3600;

      // Remove decimels
      let hours = Math.floor(currentHours);
      let mins = Math.floor(currentMin);
      let sec = Math.floor(currentSec);

      setSec(sec);
      setMins(mins);
      setHours(hours);
    });
  };

  const caption = (e) => {
    if (e.target.checked === true) {
      setCaptionIsEnabled(false);
      setAddCaption("yes");
      setC(true);
    } else if (e.target.checked === false) {
      setAddCaption("no");
      setCaptionIsEnabled(true);
      setC(false);
      dispatch(saveAudioCaption(null));
    }
  };

  const caption1 = (e) => {
    if (e.target.checked === true) {
      setCaptionIsEnabled1(false);
    } else if (e.target.checked === false) {
      setCaptionIsEnabled1(true);
      dispatch(saveTransAudioCaption(null));
    }
  };

  const audioAddCaption = (e) => {
    let index = e.target.selectedIndex;
    let option = e.target.childNodes[index];
    let langCode = option.getAttribute("langcode");

    if (captionIsEnabled === false) {
      setCaptionLang(e.target.value);
      setCaptionCode(langCode);

      let audio_add_caption = {
        name: e.target.value,
        code: langCode,
        add_caption: "true",
      };
      dispatch(saveAudioCaption(audio_add_caption));
    }
  };

  const audioTransCaption = (e) => {
    let index = e.target.selectedIndex;
    let option = e.target.childNodes[index];
    let langCode = option.getAttribute("langcode");

    if (captionIsEnabled1 === false) {
      let audio_translate_caption = {
        name: e.target.value,
        code: langCode,
        translate_caption: "true",
      };
      // dispatch(saveTransAudioCaption(audio_translate_caption));
    }
  };

  const postTranscribe = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const data = {
      user_id: user.user_id,
      campaignid: campaign_id,
      audiourl: audioUrl,
      start_time: strtTime === "" ? startTime : strtTime,
      end_time: edTime === "" ? endTime : edTime,
      add_caption: addCaption,
      code: selectedLang === null ? addCaptionCode : selectedLang.code,
      name: selectedLang === null ? addCaptionLang : selectedLang.name,
    };

    axios
      .post(`${baseURL}api/audio-transcribe-proccess`, data, config)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(trimmedAudioData(res.data.data));
        }
      });
  };

  return (
    <>
      <Helmet>
        <title> PodReel | ProfileSection</title>
      </Helmet>
      <CreateHead />
      <section className="create-prodcast-sec">
        <div className="container">
          <div className="row mt-lg-5">
            <div className="col-lg-12 col-md-12 mx-auto">
              <div className="create-tab">
                <nav>
                  <div
                    className="nav nav-tabs d-flex justify-content-between"
                    id="nav-tab"
                    role="tablist"
                  >
                    {/* <a className="nav-item  nav-link " id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="false"><span>1</span> Upload</a> */}

                    <Link className="nav-item nav-link" to="/UploadSection">
                      <span>1</span> Upload
                    </Link>
                    <a
                      className="nav-item  nav-link active "
                      id="nav-profile-tab"
                      data-toggle="tab"
                      href="#nav-profile"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="true"
                    >
                      <span>2</span>Audio
                    </a>
                    {/* <a className="nav-item  nav-link " id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false"><span>3</span> Customize</a> */}
                    <Link
                      className="nav-item  nav-link"
                      to="#"
                      style={{ cursor: "auto" }}
                    >
                      <span style={{ cursor: "auto" }}>3</span> Customize
                    </Link>
                  </div>
                </nav>
                <div
                  className="tab-content mt-lg-5 mt-md-5"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane text-white fade show active"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div className="upload-body">
                      <div className="row">
                        <div className="col-md-2 p-0">
                          <div className="audio-cliper">
                            <div
                              className="form-group m-0"
                              style={{ height: "100%" }}
                            >
                              <input
                                readOnly
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="00:00:00"
                                value={`${hours < 10 ? "0" : ""}${hours}:${
                                  mins < 10 ? "0" : ""
                                }${mins}:${sec < 10 ? "0" : ""}${sec}`}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-10 p-0"
                          style={{
                            border: "0.3px solid #747474",
                            backgroundColor: "#000",
                            cursor: "pointer",
                            position: "relative",
                          }}
                        >
                          <div
                            className="parent-dvi"
                            style={{
                              position: "absolute",
                              height: "46px",
                              width: "100%",
                            }}
                          >
                            {/* {ready === "false" ? <p className="loading">Loading dots</p> :}  */}
                            {loader ? (
                              <div class="d-flex justify-content-center">
                                <div
                                  class="spinner-border"
                                  role="status"
                                  style={{ color: "#ea5e5f", marginTop: "7px" }}
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : null}
                            <div
                              id="waveform"
                              ref={waveformRef}
                              style={{
                                position: "absolute",
                                top: "0",
                                width: "100%",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-2 pl-0 d-flex align-items-center">
                          <div className="start-time">
                            <div className="form-group m-0">
                              <label htmlFor="exampleInputEmail1">
                                START TIME
                              </label>
                              <input
                                readOnly
                                className="form-control m-0"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="00:00:00"
                                value={`${
                                  startTrimHour < 10 ? "0" : ""
                                }${startTrimHour}:${
                                  startTrimMin < 10 ? "0" : ""
                                }${startTrimMin}:${
                                  startTrimSec < 10 ? "0" : ""
                                }${startTrimSec}`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 d-flex align-items-center">
                          <div className="start-time">
                            <div className="form-group m-0">
                              <label htmlFor="exampleInputEmail1">
                                END TIME
                              </label>
                              <input
                                readOnly
                                className="form-control m-0"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="00:00:00"
                                value={`${
                                  endTrimHour < 10 ? "0" : ""
                                }${endTrimHour}:${
                                  endTrimMin < 10 ? "0" : ""
                                }${endTrimMin}:${
                                  endTrimSec < 10 ? "0" : ""
                                }${endTrimSec}`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 d-flex align-items-center justify-content-end">
                          <div className="play-icon">
                            <span>
                              <i
                                className={
                                  playing
                                    ? "far fa-pause-circle"
                                    : "far fa-play-circle"
                                }
                                onClick={play}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2 offset-md-3 pr-0">
                          <div className="start-time">
                            <div className="form-group m-0">
                              <label htmlFor="exampleInputEmail1">
                                DURATION TIME
                              </label>
                              <input
                                readOnly
                                className="form-control form-control-plaintext m-0"
                                id="staticEmail"
                                aria-describedby="emailHelp"
                                placeholder="00:00:00"
                                value={`${
                                  totalTrimDurationH < 10 ? "0" : ""
                                }${totalTrimDurationH}:${
                                  totalTrimDurationM < 10 ? "0" : ""
                                }${totalTrimDurationM}:${
                                  totalTrimDurationS < 10 ? "0" : ""
                                }${totalTrimDurationS}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-4 pl-0 mt-4">
                          <div className="card border-dark mb-3">
                            <div className="card-header d-flex justify-content-between align-items-center">
                              <div className="d-inline">ADD CAPTION</div>
                              <div className="form-check p-0  d-inline form-switch">
                                {/* checked={checked} value={checked}  */}
                                <input
                                  className="form-check-input float-end"
                                  type="checkbox"
                                  id={`flexSwitchCheckChecked, ${checked}`}
                                  onChange={caption}
                                  checked={c}
                                />
                              </div>
                            </div>
                            <div className="card-body text-darks">
                              <div className="row">
                                <div className="col-md-6">
                                  <h6>Language</h6>
                                </div>
                                <div className="col-md-6">
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    disabled={captionIsEnabled}
                                    onChange={audioAddCaption}
                                  >
                                    {selectedLang === null ||
                                    selectedLang.add_caption === "" ? (
                                      <option selected disabled>
                                        Select
                                      </option>
                                    ) : (
                                      <option selected>
                                        {selectedLang.name}
                                      </option>
                                    )}
                                    {lang1.map((l) => {
                                      return (
                                        <option
                                          key={l.id}
                                          value={l.name}
                                          langcode={l.code}
                                        >
                                          {l.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mt-4 pr-0">
                          <div className="card border-dark mb-3">
                            <div className="card-header d-flex justify-content-between align-items-center">
                              <div className="d-inline">TRANSLATE CAPTION</div>
                              <div className="form-check p-0  d-inline form-switch">
                                <input
                                  className="form-check-input float-end"
                                  type="checkbox"
                                  id="flexSwitchCheckChecked"
                                  onChange={caption1}
                                />
                              </div>
                            </div>
                            <div className="card-body text-darks">
                              <div className="row">
                                <div className="col-md-6">
                                  <h6>Language</h6>
                                </div>
                                <div className="col-md-6">
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    disabled={captionIsEnabled1}
                                    onChange={audioTransCaption}
                                  >
                                    <option selected disabled>
                                      Select
                                    </option>
                                    {lang.map((l) => {
                                      return (
                                        <option
                                          key={l.id}
                                          value={l.name}
                                          langcode={l.code}
                                        >
                                          {l.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12 d-flex justify-content-end">
                    <div className="next-btn">
                      <Link to="/CustomizeSection" onClick={postTranscribe}>
                        NEXT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfileSection);

import {
  SAVE_UPLOAD_URL,
  TRIMMED_AUDIO_DATA,
  SAVE_AUDIO_DATA,
  AUDIO_ADD_CAPTION,
  SAVE_GENERAL_DATA,
  SAVE_IMAGE_DATA,
  SAVE_PROGRESS_DATA,
  SAVE_TEXT_DATA,
  SAVE_WAVEFORM_DATA,
  AUDIO_TRANSLATE_CAPTION,
  GENERATED_CAPTION,
  TRANSLATED_CAPTION,
  UPDATED_TRANSLATED_CAPTION,
  UPDATED_GENERATED_CAPTION,
  DURATION,
  EDITED_DATA,
  RESET_DATA,
  ADD_TEXT,
  ADD_TEXT_PROPERTY,
  ADD_TEXT_COLOR,
  ADD_TEXT_FONT,
  WAVE_POS,
  IMG_POS,
  CHANGE_IMG_SELECTED,
  TEXT_POSITION,
  REMOVE_WAVEFORM_DATA,
  ADD_FONT_SIZE,
} from "../actions/Types";

const initialState = {
  // audio_file_url: "",
  audio_file_data: {
    url: "",
    campaingCode: "",
    campaignName: "",
  },
  audio_data: {
    audioAddCaption: null,
    audioTranslateCaption: null,
  },
  trimmed_audio_data: {
    campaign_id: "",
    job_name: "",
    trimaudio: "",
  },

  duration: {
    startTime: "",
    endTime: "",
    totalDuration: "",
  },

  generated_Captions: {
    captions: null,
  },

  translated_Captions: {
    captions: null,
  },

  customize: {
    general: {
      dimension: "16:9",
      width: 480,
      height: 270,
      backgroundColor: "#f56262",
    },
    waveform: {
      name: "",
      id: "",
      color: "",
      posX: 0,
      posY: 0,
      width: 200,
      height: 100,
      isSelected: false,
    },
    image: [
      {
        url: "",
        id: "",
        width: "",
        height: "",
        posx: "",
        posy: "",
        left: "",
        isSelected: false,
      },
    ],
    text: [],
    progress: {
      id: "",
      show: false,
      color: "",
      width: "",
      height: "",
      top: "",
      left: "",
    },
  },

  text_position: {
    position: "centered",
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_UPLOAD_URL:
      return {
        ...state,
        audio_file_data: {
          ...state.audio_file_data,
          url: payload.url,
          campaingCode: payload.campaignid,
          campaignName: payload.cpampaign_name,
        },
      };

    case EDITED_DATA:
      state.audio_file_data.url = payload.origin_audio;
      state.audio_file_data.campaignName = payload.podcast_name;
      state.audio_file_data.campaingCode = payload.id;

      state.duration.startTime = JSON.parse(payload.created_json).start_time;
      state.duration.endTime = JSON.parse(payload.created_json).end_time;
      state.duration.totalDuration = JSON.parse(payload.created_json).duration;

      state.audio_data.audioAddCaption = JSON.parse(
        payload.created_json
      ).audio_add_caption[0];
      state.audio_data.audioTranslateCaption = JSON.parse(
        payload.created_json
      ).audio_translate_caption[0];
      state.trimmed_audio_data.job_name = payload.job_name;

      state.customize.general.dimension = JSON.parse(
        payload.created_json
      ).customize.general[0].dimension;
      state.customize.general.width = JSON.parse(
        payload.created_json
      ).customize.general[0].width;
      state.customize.general.height = JSON.parse(
        payload.created_json
      ).customize.general[0].height;
      state.customize.general.backgroundColor = JSON.parse(
        payload.created_json
      ).customize.general[0].backgroundcolor;

      state.customize.waveform.name = JSON.parse(
        payload.created_json
      ).customize.waveform[0].mode;

      state.customize.image[0].url = payload.image;

      state.customize.text = JSON.parse(payload.created_json).customize.text;

      return {
        ...state,
      };

    case AUDIO_ADD_CAPTION:
      return {
        ...state,
        audio_data: { ...state.audio_data, audioAddCaption: payload },
      };

    case AUDIO_TRANSLATE_CAPTION:
      return {
        ...state,
        audio_data: { ...state.audio_data, audioTranslateCaption: payload },
      };

    case TRIMMED_AUDIO_DATA:
      return {
        ...state,
        trimmed_audio_data: {
          ...state.trimmed_audio_data,
          campaign_id: payload.campaignid,
          job_name: payload.job_name,
          trimaudio: payload.trimaudio,
        },
      };

    case DURATION:
      state.duration.startTime = payload.startT;
      state.duration.endTime = payload.endT;
      state.duration.totalDuration = payload.totalD;
      return {
        ...state,
        // duration: { ...state.duration, startTime: payload.startT, endTime: payload.endT, totalDuration: payload.totalD},
      };

    case GENERATED_CAPTION:
      return {
        ...state,
        generated_Captions: { ...state.generated_Captions, captions: payload },
      };

    case TRANSLATED_CAPTION:
      return {
        ...state,
        translated_Captions: {
          ...state.translated_Captions,
          captions: payload,
        },
      };

    case UPDATED_TRANSLATED_CAPTION:
      let dt = JSON.parse(payload);
      state.translated_Captions.captions[dt.index].content = dt.data;
      return {
        ...state,
      };

    case UPDATED_GENERATED_CAPTION:
      let dt1 = JSON.parse(payload);
      state.generated_Captions.captions[dt1.index].text = dt1.data;
      return {
        ...state,
      };

    //TEXT
    case ADD_TEXT:
      const textFinal = state.customize.text.map((item, index) => {
        if (index === payload.index) {
          return { ...item, text: payload.data };
        } else {
          return item;
        }
      });
      return {
        ...state,
        customize: { ...state.customize, text: textFinal },
      };

    case ADD_TEXT_PROPERTY:
      const textProperties = state.customize.text.map((item, index) => {
        if (index === payload.index) {
          return { ...item, isSelected: true };
        } else {
          return { ...item, isSelected: false };
        }
      });
      return {
        ...state,
        customize: { ...state.customize, text: textProperties },
      };

    case ADD_TEXT_COLOR:
      const color = state.customize.text.map((item, index) => {
        if (index === payload.index) {
          return { ...item, color: payload.data };
        } else {
          return { ...item };
        }
      });
      return {
        ...state,
        customize: { ...state.customize, text: color },
      };

    case ADD_FONT_SIZE:
      const size = state.customize.text.map((item, index) => {
        if (index === payload.index) {
          return { ...item, fontsize: parseInt(payload.data) };
        } else {
          return { ...item };
        }
      });
      return {
        ...state,
        customize: { ...state.customize, text: size },
      };

    case ADD_TEXT_FONT:
      const font = state.customize.text.map((item, index) => {
        if (index === payload.index) {
          return {
            ...item,
            fontfamily: payload.data[1],
            fontUrl: payload.data[0],
          };
        } else {
          return { ...item };
        }
      });
      return {
        ...state,
        customize: { ...state.customize, text: font },
      };

    case CHANGE_IMG_SELECTED:
      const changeIsSelected = state.customize.image.map((item, index) => {
        if (index === payload.data) {
          return { ...item, isSelected: true };
        } else {
          return { ...item, isSelected: false };
        }
      });
      return {
        ...state,
        customize: { ...state.customize, image: changeIsSelected },
      };

    case WAVE_POS:
      state.customize.waveform.posX = payload.data.posX;
      state.customize.waveform.posY = payload.data.posY;
      return {
        ...state,
        customize: {
          ...state.customize,
        },
      };

    case IMG_POS:
      state.customize.image[state.customize.image.length - 1].posx =
        payload.data.posX;
      state.customize.image[state.customize.image.length - 1].posy =
        payload.data.posY;
      state.customize.image[state.customize.image.length - 1].width =
        payload.data.width;
      state.customize.image[state.customize.image.length - 1].height =
        payload.data.height;
      return {
        ...state,
        customize: {
          ...state.customize,
        },
      };

    case SAVE_GENERAL_DATA:
      return {
        ...state,
        customize: { ...state.customize, general: payload },
      };
    case SAVE_WAVEFORM_DATA:
      return {
        ...state,
        customize: { ...state.customize, waveform: payload },
      };
    case REMOVE_WAVEFORM_DATA:
      return {
        ...state,
        customize: { ...state.customize, waveform: payload },
      };

    case SAVE_IMAGE_DATA:
      return {
        ...state,
        customize: { ...state.customize, image: payload },
      };
    case SAVE_TEXT_DATA:
      return {
        ...state,
        customize: { ...state.customize, text: payload },
      };

    case SAVE_PROGRESS_DATA:
      return {
        ...state,
        customize: { ...state.customize, progress: payload },
      };
    case TEXT_POSITION:
      const pos = state.customize.text.map((item, index) => {
        if (index === payload.index) {
          return { ...item, position: payload.data };
        } else {
          return { ...item };
        }
      });

      return {
        ...state,
        customize: { ...state.customize, text: pos },
      };

    // const color = state.customize.text.map((item, index) => {
    //   if (index === payload.index) {
    //     return { ...item, color: payload.data };
    //   } else {
    //     return { ...item };
    //   }
    // });
    // return {
    //   ...state,
    //   customize: { ...state.customize, text: pos },
    // };

    case RESET_DATA:
      state.audio_file_data.url = "";
      state.audio_file_data.campaignName = "";
      state.audio_file_data.campaingCode = "";

      state.duration.startTime = "";
      state.duration.endTime = "";
      state.duration.totalDuration = "";

      state.customize.image = [];
      state.audio_data.audioAddCaption = null;
      state.audio_data.audioTranslateCaption = null;
      state.generated_Captions.captions = null;
      state.translated_Captions.captions = null;
      state.trimmed_audio_data.job_name = "";
      state.trimmed_audio_data.campaign_id = "";
      state.trimmed_audio_data.trimaudio = "";

      state.customize.general.dimension = "16:9";
      state.customize.general.width = 480;
      state.customize.general.height = 270;
      state.customize.general.backgroundColor = "#f56262";

      state.customize.waveform.name = "";
      state.customize.text = [];
      state.customize.image = [
        {
          url: "",
          id: "",
          width: "",
          height: "",
          posx: "",
          posy: "",
          left: "",
          isSelected: false,
        },
      ];

      state.customize.progress.show = false;
      state.customize.progress.color = "";
      state.customize.progress.id = "";
      return { ...state };

    default:
      return state;
  }
}

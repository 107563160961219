import * as React from "react";
import Video from "./Video";
import { Link } from "react-router-dom";

export function RecentVideo() {
  return (
    <div>
      <section id="project-section" className="bg-dark">
        <div className="container-fluid px-4">
          <div className="row row-1 align-items-end ">
            <div className="col colmn-1">
              <div className="title-area">
                <p
                  className="text-white text-left"
                  style={{ fontWeight: 600, fontSize: "16px" }}
                >
                  Recently Created Videos
                </p>
              </div>
            </div>
            <div className="col colmn-2 text-right">
              <Link
                className="view-all flex-elem justify-content-end font-weight-bold"
                to="/my-podcast"
                style={{ color:"#fff", fontWeight: 600, fontSize: "16px" }}
              >
                View All
                <span className="icon arrow pl-2" style={{ color: "#fff" }}>
                  <svg width="18px" height="18px" viewBox="0 0 28 28">
                    <use xlinkHref="./assets/images//right-arrow.svg#rght-arrow" />
                  </svg>
                </span>
              </Link>
            </div>
          </div>
          <div className="row row-2">
            <Video />
          </div>
        </div>
      </section>
    </div>
  );
}

export default RecentVideo;

import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import AccountManage from "./AccountManage";
import AddAccount from "./AddAccount";
import Alert from "../alert/Alert";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

export function Account({ auth: { user, loading } }) {

  const [showAlert, setShowAlert] = useState(false);
  const [is_client, setIsClient] = useState(false);
  const [is_team, setIsTeam] = useState(false);
  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
      setIsTeam(loading || !user.is_team ? false : user.is_team);
    }
  }, [user, loading]);

  if (is_client === true || is_team === true) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div>
      <Helmet>
        <title> PodReel | Account</title>
      </Helmet>

      <style
        dangerouslySetInnerHTML={{
          __html:
            ".form-group-mb { margin-bottom: -17px !important;} .input-dark-accnt .personal-info{font: 400 16px/1.5 'SourceSansPro', sans-serif;color: #fff !important;padding: 22px 12px 25px;border-radius: 8px !important;border: solid 1px #363839;background-color: #363839 !important;height: auto;box-shadow: none;resize: none; height: 56px;letter-spacing: 1px;}#project-section{padding: 48px 0 33px ;} ",
        }}
      />
      {showAlert ? <Alert /> : null}
      <section
        id="project-section"
        className="mt-5"
        style={{ backgroundColor: "#171717" }}
      >
        <AddAccount showAlert={setShowAlert} />
      </section>

      <section id="project-section" style={{ backgroundColor: "#171717" }}>
        <AccountManage showAlert={setShowAlert} />
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Account);

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { baseURL } from "../../../../global/global";
import WebFont from "webfontloader";
import Cline from "../../../../waveforms/Cline";
import Line from "../../../../waveforms/Line";
import P2p from "../../../../waveforms/P2p";
import Point from "../../../../waveforms/Point";
import ImageMovable from "../ImageMovable";
import progress1Img from "../../../../progressbar/progress1.png";

import {
  saveWaveformData,
  addText,
  addTextProperty,
  wavePos,
  imgPos,
  changeImgSelected,
} from "../../../../actions/createPodreel";
import { connect, useSelector, useDispatch } from "react-redux";
import Moveable from "react-moveable";
import ContentEditable from "react-contenteditable";

const Preview = (props) => {
  const editedColor = useSelector(
    (state) => state.createPodreel.customize.general.backgroundColor
  );
  const allText = useSelector((state) => state.createPodreel.customize.text);
  const stateData = useSelector(
    (state) => state.createPodreel.customize.waveform
  );
  const generatedCaptions = useSelector(
    (state) => state.createPodreel.generated_Captions.captions
  );
  const dimension = useSelector(
    (state) => state.createPodreel.customize.general.dimension
  );
  const position = useSelector(
    (state) => state.createPodreel.text_position.position
  );
  const [target, setTarget] = React.useState();
  const [id, setId] = useState();
  const [selectObject, setSelectObject] = useState([]);
  const [text, setText] = useState(props.customize.text);
  const dispatch = useDispatch();
  const editText = useRef("Dummy Text");

  const [textIndex, setTextIndex] = useState("");

  const [pos, setPos] = useState({
    posX: 10,
    posY: 10,
    width: "300",
    height: "200",
  });
  //New
  const [moveImage, setMoveImage] = useState(false);

  const [layer, setLayer] = useState("");
  const [textId, setTextId] = useState();
  const [fonts, setFonts] = useState([]);
  const [frame, setFrame] = React.useState({
    translate: [0, 0],
    scale: [1, 1],
    transformOrigin: "50% 50%",
  });
  const [extn, setExtn] = useState("");

  const [prev, setPrev] = useState({
    general: {
      dimension: "16:9",
      width: "480",
      height: "270",
      backgroundColor: "#f56262",
    },
    waveform: {
      name: "",
      color: "",
      top: "",
      left: "",
      width: "",
      height: "",
      isSelected: false,
      id: "",
    },
    image: [],
    text: [
      {
        isSelected: false,
      },
    ],
    progress: {
      show: false,
      color: "#ffffff",
    },
  });

  useEffect(() => {
    if (prev.waveform.isSelected === true) {
      dispatch(wavePos(pos));
    }
  }, [pos]);

  useEffect(() => {
    const objectArray = [];
    setPrev({
      ...props.customize,
    });
  }, [props.customize]);

  useEffect(() => {
    if (prev.waveform.name === "cline" && prev.waveform.isSelected === true) {
      setId(prev.waveform.id);
    } else if (
      prev.waveform.name === "line" &&
      prev.waveform.isSelected === true
    ) {
      setId(prev.waveform.id);
    } else if (
      prev.waveform.name === "p2p" &&
      prev.waveform.isSelected === true
    ) {
      setId(prev.waveform.id);
    } else if (
      prev.waveform.name === "point" &&
      prev.waveform.isSelected === true
    ) {
      setId(prev.waveform.id);
    }
  }, [prev.waveform.name]);

  useEffect(() => {
    const data = { page_number: 1 };
    let f = [];
    axios.post(`${baseURL}api/fonts-get`, data).then((res) => {
      if (res.data.status === true) {
        res.data.data.map((fontName) => {
          f.push(fontName.family);
        });
        setFonts(f);
      }
    });
  }, []);

  useEffect(() => {
    setText(props.customize.text);
  }, [JSON.stringify(props.customize.text)]);

  useEffect(() => {
    if (fonts.length > 0) {
      WebFont.load({
        google: {
          families: fonts,
        },
      });
    }
  }, [fonts]);

  const style = {
    background:
      editedColor === "#f56262"
        ? props.customize.general.backgroundColor
        : editedColor,
    width: props.customize.general.width,
    height: props.customize.general.height,
    overflow: "hidden",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    height: dimension === "9:16" ? "100% !important" : "",
  };

  const handleChange = (e, i) => {
    editText.current = e.target.value;

    dispatch(addText(e.target.value, i));
  };

  const addMovable = (e, i) => {
    if (e === "isText") {
      setTarget(document.getElementsByClassName(`textTarget_${i}`));
      dispatch(addTextProperty("true", i));
      setTextIndex(i);
      setTextId(i);
      setPos({ posX: "100", posY: "50" });
    } else if (e === "isImage") {
      // setTarget(document.getElementsByClassName("imageTarget"));

      setPos({ posX: "100", posY: "100" });
      // dispatch(changeImgSelected(i));
    } else {
      setTarget(document.getElementsByClassName(id));
      setPos({ posX: "100", posY: "150" });
    }
  };

  const move = () => {
    setMoveImage(!moveImage);
  };

  useEffect(() => {
    let ext;
    if (prev.image.length > 0 && prev.image[0].url !== "") {
      ext = prev.image[0].url.match(/\.([^\./\?]+)($|\?)/)[1];

      setExtn(ext);
    }
  }, [prev.image]);

  return (
    <>
      <div
        className={`preview-tab ${dimension === "9:16" ? "preview-9" : ""} `}
        style={{ overflow: "hidden" }}
      >
        <div className="canvas" style={style}>
          {prev.waveform.name === "cline" ? (
            <div
              className={prev.waveform.id}
              style={{ zIndex: "2" }}
              onClick={() => addMovable("wave")}
            >
              <Cline />
            </div>
          ) : prev.waveform.name === "line" ? (
            <div
              style={{ zIndex: "2" }}
              className={prev.waveform.id}
              onClick={() => addMovable("wave")}
            >
              <Line />
            </div>
          ) : prev.waveform.name === "p2p" ? (
            <div
              style={{ zIndex: "2" }}
              className={prev.waveform.id}
              onClick={() => addMovable("wave")}
            >
              <P2p />
            </div>
          ) : prev.waveform.name === "point" ? (
            <div
              style={{ zIndex: "2" }}
              className={prev.waveform.id}
              onClick={() => addMovable("wave")}
            >
              <Point />
            </div>
          ) : null}
          <div
            className="text-wrapper"
            style={{
              width: "100%",
              position: "absolute",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {text.length > 0
              ? text.map((txt, i) => {
                  return (
                    <>
                      <div
                        className={`textTarget_${i} ${txt.position}`}
                        onClick={(e) => addMovable("isText", i)}
                        style={{
                          // position: "absolute",
                          zIndex: "3",
                          width: "unset",
                          // height:'100%'
                        }}
                      >
                        <ContentEditable
                          html={allText[i].text} // innerHTML of the editable div
                          disabled={false} // use true to disable editing
                          onChange={(e) => handleChange(e, i)} // handle innerHTML change
                          tagName="p"
                          style={
                            {
                              color: `${txt.color}`,
                              outline: "none",
                              fontFamily: `${txt.fontfamily}`,
                              display: "inline-block",
                              fontSize: `${txt.fontsize}px`,
                            }
                            // position === "center" ? { textAlign: "center" } : "")
                          }
                          className={`${textId === i ? "custom-border" : ""}`}
                        />
                      </div>
                      <br />
                    </>
                  );
                })
              : null}
          </div>
          {prev.image.length > 0 && prev.image[0].url !== "" ? (
            <div className="image-wrapper">
              {extn === "mp4" ? (
                <video
                  src={prev.image.length > 0 ? prev.image[0].url : ""}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: "1",
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: "0",
                    right: "0",
                    textAlign: "center",
                  }}
                  className="imageTarget"
                  // onClick={(e) =>
                  //   addMovable(
                  //     "isImage",
                  //     prev.image.length > 0 ? prev.image[prev.image.length - 1] : ""
                  //   )
                  // }
                  autoPlay
                  onClick={move}
                />
              ) : (
                <img
                  src={prev.image.length > 0 ? prev.image[0].url : ""}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: "1",
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: "0",
                    right: "0",
                    textAlign: "center",
                  }}
                  className="imageTarget"
                  // onClick={(e) =>
                  //   addMovable(
                  //     "isImage",
                  //     prev.image.length > 0 ? prev.image[prev.image.length - 1] : ""
                  //   )
                  // }
                  onClick={move}
                />
              )}
            </div>
          ) : null}

          {generatedCaptions !== null ? (
            <h6
              style={
                prev.general.dimension === "9:16"
                  ? {
                      top: "65%",
                      textAlign: "center",
                      top: "86%",
                      position: "sticky",
                      fontSize: "15px",
                      color: "#fff",
                      backgroundColor: "#000",
                    }
                  : {
                      textAlign: "center",
                      top: "86%",
                      position: "sticky",
                      fontSize: "15px",
                      color: "#fff",
                      backgroundColor: "#000",
                    }
              }
            >
              Your Generated Captions
            </h6>
          ) : null}

          {prev.progress.show === true ? (
            <img
              src={progress1Img}
              alt=""
              style={
                prev.general.dimension === "9:16"
                  ? {
                      top: "99%",
                      width: "100%",
                      position: "absolute",
                      zIndex: "9",
                    }
                  : {
                      width: "100%",
                      top: "97%",
                      position: "absolute",
                      zIndex: "9",
                    }
              }
            />
          ) : null}
        </div>
      </div>
      {/* 
      <Moveable
        target={target}
        container={document.querySelector(".canvas")}
        scalable={true}
        resizable={true}
        draggable={true}
        originRelative={true}
        keepRatio={false}
        throttleScale={0}
        throttleDrag={0}
        renderDirections={["nw", "n", "ne", "w", "e", "sw", "s", "se"]}
        edge={false}
        zoom={1}
        origin={true}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        onScaleStart={(e) => {
          e.set(frame.scale);
          e.dragStart && e.dragStart.set(frame.translate);
        }}
        onScale={(e) => {
          const beforeTranslate = e.drag.beforeTranslate;

          frame.translate = beforeTranslate;
          frame.scale = e.scale;
          e.target.style.transform =
            `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)` +
            ` scale(${e.scale[0]}, ${e.scale[1]})`;
        }}
        onResizeStart={(e) => {
          e.setOrigin(["%", "%"]);
          e.dragStart && e.dragStart.set(frame.translate);
        }}
        onResize={(e) => {
          const beforeTranslate = e.drag.beforeTranslate;

          frame.translate = beforeTranslate;
          e.target.style.width = `${e.width}px`;
          e.target.style.height = `${e.height}px`;
          e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;

          setPos({ ...pos, width: e.width, height: e.height });
        }}
        onDragOriginStart={(e) => {
          e.dragStart && e.dragStart.set(frame.translate);
        }}
        onDragOrigin={(e) => {
          frame.translate = e.drag.beforeTranslate;
          frame.transformOrigin = e.transformOrigin;
        }}
        onDragStart={(e) => {
          e.set(frame.translate);
        }}
        onDrag={(e) => {
          frame.translate = e.beforeTranslate;
          setPos({ ...pos, posX: e.translate[0], posY: e.translate[1] });
        }}
        onRender={(e) => {
          const { translate, transformOrigin } = frame;
          e.target.style.transformOrigin = transformOrigin;
          e.target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`;
        }}
      /> */}
      {moveImage ? (
        <ImageMovable
          t={document.getElementsByClassName("imageTarget")}
          c={document.querySelector(".canvas")}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  customize: state.createPodreel.customize,
});

export default connect(mapStateToProps)(Preview);
